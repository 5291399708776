@mixin alinearFlex {
  display: flex;
  justify-content: center;
  align-items: center;
}
@mixin sombraRedonda {
  box-shadow: 4px 0px 15px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
}

@mixin ralewayNormal($tamano) {
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: $tamano;
}
@mixin avenirNormal($tamano) {
  font-family: Avenir;
  font-style: normal;
  font-size: $tamano;
}
@mixin imagenFondo {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
@mixin estiloInput {
  border: 1px solid #c5c5c5;
  box-sizing: border-box;
  border-radius: 10px;
  background-color: transparent;
}
@mixin clearfix() {
  &::after {
    display: block;
    content: "";
    clear: both;
  }
}
