@import "../../../mixins.scss";

.contenedorExtraer {
  width: 90%;
  margin: 50px auto;
  p {
    @include ralewayNormal(16px);
    text-align: left;
  }
  .contenedorOpciones {
    margin-top: 40px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    @include avenirNormal(16px);

    .selectorOpciones {
      @include sombraRedonda();

      padding: 30px 20px;
      color: #7c7c7c;
      font-weight: 500;
      border-radius: 10px;
      select {
        padding: 5px 15px;
        margin-left: 20px;
        border: none;
      }
    }
    .monedaOpciones {
      @include alinearFlex();
      font-weight: 500;

      select {
        padding: 10px 30px;
        margin-left: 20px;
        border: none;
        background-color: #c4c4c4;
        border-radius: 10px;
      }
    }
    .montoOpciones {
      @include alinearFlex();
      input {
        width: 111px;
        padding: 10px 0;
        margin-left: 20px;
        border: none;
        background-color: #c4c4c4;
        border-radius: 10px;
      }
    }
  }
}
.extraer {
  form {
    text-align: center;
    textarea {
      @include estiloInput();
      width: 60%;
      margin: 20px 20%;
    }

    input[type="submit"] {
      padding: 10px 30px;
    }
  }
}
@media screen and (max-width: 900px) {
  .contenedorExtraer {
    .contenedorOpciones {
      grid-template-columns: 1fr;
    }
  }
}
