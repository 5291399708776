@import "../../../mixins.scss";

.descargaSirix {
  margin-top: 50px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  .sirixPC {
    @include alinearFlex();
    img {
      width: 120px;
    }
    span {
      @include ralewayNormal(16px);
      font-weight: bold;
      text-transform: uppercase;
    }
  }
  .sirixMovil {
    @include alinearFlex();

    span {
      @include ralewayNormal(16px);
      font-weight: bold;
    }
  }
  .sirixPlataforma {
    @include sombraRedonda();
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    text-align: center;
    padding: 0 5px;
    border-radius: 10px;
    min-height: 75px;
    div {
      @include alinearFlex();
    }

    .tituloPlataforma {
      select {
        @include avenirNormal(16px);
        padding: 5px 20px;
        background-color: transparent;
        border: none;
      }
      p {
        @include avenirNormal(16px);
        color: #7c7c7c;
      }
    }
    .logoPlataforma {
      @include avenirNormal(16px);
      color: #7c7c7c;
      p {
        margin: 0;
      }
      img {
        width: 120px;
      }
    }
    .botonPlataforma {
      button {
        @include avenirNormal(11px);
        padding: 5px 10px;
      }
    }
  }
  .sirixiOS {
    grid-column: 2/3;
    grid-row: 3/4;
  }
}
@media screen and (max-width: 900px) {
  .descargaSirix {
    grid-template-columns: 1fr;
    .sirixiOS {
      grid-column: 1/2;
      grid-row: 5/6;
    }
  }
}
@media screen and (max-width: 500px) {
  .descargaSirix {
    .sirixPlataforma {
      grid-template-columns: 1fr;
      padding: 20px 0;
    }
  }
}
