@import "../../mixins.scss";

.abrirMenu {
  position: absolute;
  display: none;
  img {
    width: 25px;
  }
}
.cerrarMenu {
  position: absolute;
  display: none;
  img {
    width: 15px;
  }
}
.abrirMenu {
  background-color: transparent;
  border: none;
  top: -66px;
  z-index: 1;
  right: 10px;
}
.cerrarMenu {
  right: 5px;
  top: -20px;
}

.menuLateral {
  background-color: var(--gris);
  padding: 30px;
  display: flex;
  flex-direction: column;
  min-width: 250px;
  max-width: 300px;

  .contenedorPerfilMovil {
    display: none;
    text-align: center;
    margin-top: 20px;
    img {
      max-width: 85px;
      border-radius: 50px;
    }
    .botonesAccion {
      .perfil {
        border-right: 1px solid var(--amarillo);
        padding-right: 10px;
        margin-right: 0;
      }
      button {
        margin: 5px;
      }
    }
  }

  button {
    @include ralewayNormal(15px);
    margin: 20px 0 0 0;
    border: none;
    background-color: transparent;
    text-align: left;
    padding: 10px;
    cursor: pointer;
    color: #7c7c7c;

    &:hover {
      color: var(--amarillo);
    }
  }
  .enlaces {
    padding: 5px 20px;
    display: none;
    a {
      @include ralewayNormal(12px);
      display: block;
      margin: 5px;
      text-decoration: none;
      color: #7c7c7c;
      &:hover {
        color: var(--amarillo);
      }
    }
  }
  .paginaActiva {
    color: var(--amarillo) !important;
  }
}

@media screen and (max-width: 900px) {
  .menuLateral {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -100%;
    z-index: 3;
    transition: all 0.3s ease;
    .contenedorPerfilMovil {
      display: block;
    }
  }
  .menuAbierto {
    left: 0;
  }
  .abrirMenu,
  .cerrarMenu {
    display: block;
  }
}
