.piePagina {
  background-color: black;
}
footer {
  background-color: black;
  width: 100%;
  padding: 20px 0;
  position: relative;
  width: 80%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logoFooter {
  width: 130px;
}
.logosSociales {
  width: 130px;
  display: flex;
  justify-content: space-evenly;
}
.logosSociales img {
  width: 20px;
}
.enlacesPrivacidad {
  color: white;
  display: grid;
  grid-template-areas:
    "cuentasFooter legalesFooter"
    "acercaFooter legalesFooter"
    "plataformaFooter legalesFooter";
}
.cuentasFooter {
  grid-area: cuentasFooter;
}
.acercaFooter {
  grid-area: acercaFooter;
}
.plataformaFooter {
  grid-area: plataformaFooter;
}
.legalesFooter {
  grid-area: legalesFooter;
}
.enlacesPrivacidad div ul li,
.enlacesPrivacidad span {
  list-style: none;
  font-family: Avenir;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 18px;
}
.enlacesPrivacidad div ul li a {
  text-decoration: none;
  color: rgb(194, 194, 194);
}

.enlacesPrivacidad div span {
  font-weight: 500;
  font-size: 14px;
}
.enlacesPrivacidad div {
  padding: 10px 20px;
}
.enlacesPrivacidad ul {
  padding-left: 0;
}
/* SECTION  PAGOS*/
.contenedorPagos {
  background-color: #ccc;
  display: flex;
  justify-content: space-around;
  padding: 10px 0;
}
.contenedorPagos img {
  width: 35px;
  margin: 5px;
}

@media screen and (max-width: 900px) {
  .contenedorPagos {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    text-align: center;
  }
  footer {
    flex-direction: column;
  }
  .redesSociales {
    text-align: center;
  }
  .logosSociales {
    margin: 10px auto;
  }
  .enlacesPrivacidad {
    text-align: center;
  }
}
@media screen and (max-width: 500px) {
  .contenedorPagos {
    grid-template-columns: repeat(5, 1fr);
  }
  .enlacesPrivacidad {
    grid-template-areas:
      "cuentasFooter"
      "acercaFooter"
      "plataformaFooter"
      "legalesFooter";
  }
  .enlacesPrivacidad div ul li,
  .enlacesPrivacidad span {
    font-size: 10px;
  }
  .enlacesPrivacidad div span {
    font-size: 12px;
  }
}
