@import "../../../mixins.scss";
.contenedorContacto {
  text-align: center;
  width: 70%;
  margin: auto;
  p,
  span {
    @include ralewayNormal(18px);
  }
  p {
    font-weight: 800;
  }
  form {
    @include sombraRedonda();
    padding: 30px;
    margin: 50px auto;
    width: 80%;
    text-align: left;
    @include ralewayNormal(13px);
    font-weight: bold;
    label {
      margin-bottom: 5px;
      margin-top: 10px;
      display: block;
    }
    input,
    textarea {
      @include estiloInput();
      width: 100%;
    }
    input {
      padding: 10px;
      margin: 10px 0;
    }
    input[type="submit"] {
      background-color: var(--amarillo);
      width: 30%;
      margin: 30px 35%;
      color: white;
    }
  }
}

@media screen and (max-width: 900px) {
  .contenedorContacto {
    width: 90%;
    form {
      width: 100%;
    }
  }
}
@media screen and (max-width: 550px) {
  .contenedorContacto {
    width: 100%;
  }
}
