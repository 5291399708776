@import "../../../mixins.scss";
.contenedorResumen {
  width: 100%;
  height: 100%;

  .cajas {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
    margin-bottom: 30px;
    .cajaResumen {
      @include sombraRedonda;

      padding: 10px;
      text-align: center;

      span {
        text-transform: uppercase;
        @include avenirNormal(15px);
        font-weight: 500;
        margin: 10px 0;
        display: block;
      }
      p {
        @include avenirNormal(18px);
        font-weight: 500;
      }
    }
    button {
      grid-column: 4/5;
      margin: 10px 0 0 auto;
      background-color: var(--verde);
      width: 60%;
      padding: 10px;
      color: white;
      cursor: pointer;

      a {
        text-decoration: none;
        color: inherit;
      }
    }
  }
  .tablaResumen {
    @include sombraRedonda();
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    text-align: center;
    margin-bottom: 80px;
    span {
      @include avenirNormal(14px);
      @include alinearFlex();
      font-weight: 800;

      border-bottom: 1px solid #c5c5c5;
      width: 100%;
      padding: 10px 0;
      text-transform: uppercase;
      color: #555555;
      min-height: 65px;
    }
    p {
      @include avenirNormal(12px);
      font-weight: 500;
      padding: 20px 0;
    }
    .celdaIconos {
      img {
        width: 30px;
        margin: 10px;
      }
    }
  }
}
@media screen and (max-width: 900px) {
  .contenedorResumen {
    .cajas {
      grid-template-columns: 1fr 1fr;
      button {
        grid-column: 2/3;
        width: 100%;
      }
    }
    .tablaResumen {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}
@media screen and (max-width: 550px) {
  .contenedorResumen {
    .cajas {
      grid-template-columns: 1fr;
      button {
        grid-column: 1/2;
        width: 60%;
        margin: auto;
      }
    }
    .tablaResumen {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
