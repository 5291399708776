@import "../../mixins.scss";

.opcionEfectivo {
  @include sombraRedonda();
  @include alinearFlex();
  flex-direction: column;
  padding: 20px 0;
  margin: 40px 0;
  text-align: center;
  img {
    max-height: 50px;
    margin-bottom: 20px;
  }
  form {
    input {
      margin: 0 0 10px 0;
    }
    button {
      font-size: 13px;
      padding: 5px 10px;
    }
  }
}
.monedaDeposito {
  text-align: center;
}
