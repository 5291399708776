@import "../../../mixins.scss";

.timepicker-input {
  pointer-events: none;
}
.tabla {
  overflow-x: auto;
  max-width: 95vw;
}
.actividad {
  .contenedorCuenta {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    @include avenirNormal(13px);

    select {
      @include estiloInput();
      border: none;
      margin-left: 10px;
      padding: 5px 15px;
    }
  }

  .tablaResumen {
    @include sombraRedonda();
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    text-align: center;
    margin-bottom: 80px;
    span {
      @include avenirNormal(14px);
      @include alinearFlex();
      font-weight: 800;

      border-bottom: 1px solid #c5c5c5;
      width: 100%;
      padding: 10px 0;
      text-transform: uppercase;
      color: #555555;
      min-height: 65px;
    }
    p {
      @include avenirNormal(12px);
      font-weight: 500;
      padding: 20px 0;
    }
    .react-datepicker-popper {
      z-index: 5 !important;
    }
    .timepicker-input {
      padding-top: 2.5px;
      padding-bottom: 2.5px;
    }
  }
  .contenedorFiltros {
    .filtros {
      @include sombraRedonda();

      padding: 45px 0;
      display: grid;
      grid-template-columns: 1fr 2fr 1fr;
      text-align: center;
      input,
      select {
        border: none;
        border-bottom: 1px solid #ccc;
        margin-left: 5px;
        text-align: center;
        padding: 5px 10px;
      }
      button {
        @include avenirNormal(12px);
        padding: 10px 20px;
      }
    }
    .selectorOperaciones {
      margin-top: 50px;
      text-align: center;
      button {
        margin: 30px;
        padding: 5px 10px;
        &:hover,
        .activo {
          background-color: #7c7c7c;
          color: white;
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .actividad {
    .tablaResumen {
      grid-template-columns: repeat(4, 1fr);
    }
    .contenedorFiltros {
      .filtros {
        div {
          flex-direction: column;
        }
        grid-template-columns: 1fr;
        grid-gap: 40px;
      }
    }
  }
}
