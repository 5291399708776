@import "../../../mixins.scss";

.tickets {
  min-height: 1200px;
  width: 100%;
  overflow-x: hidden;

  h1 {
    margin-top: 50px;
  }
}

// .columnaCentrada {
//   text-align: center;
// }
// .tablaGriddle {
//   @include sombraRedonda();
//   margin: 10px 0;
//   min-width: 500;
// }

// .tabla {
//   width: 100%;
//   overflow-x: auto;
// }
