@import "../../mixins.scss";
.tarjetaUnica {
  width: 100%;
  margin: 30px 0;
  img {
    width: 70%;
    margin: auto 15%;
  }
  position: relative;
  .numeroTarjeta {
    color: white;
    position: absolute;
    z-index: 120;
    top: 60%;
    left: 20%;
    @include avenirNormal(22px);
  }
  .nombreTarjeta {
    color: white;
    position: absolute;
    z-index: 120;
    bottom: 15%;
    left: 20%;
    @include avenirNormal(18px);
  }
}
@media screen and (max-width: 550px) {
  .tarjetaUnica {
    .numeroTarjeta {
      @include avenirNormal(14px);
      top: 50%;
    }
    .nombreTarjeta {
      @include avenirNormal(12px);
      bottom: 20%;
    }
  }
}
