@import "./mixins.scss";
@import url("http://db.onlinewebfonts.com/c/09c6fa9769528ae2357e67e71c192ee0?family=AvenirNext + LT + Pro + Heavy");
* {
  margin-top: 0;
  padding-top: 0;
  box-sizing: border-box;

  min-height: 0;
  min-width: 0;
}
@font-face {
  font-family: Avenir;
  font-weight: 400;
  src: url("./assets/fonts/AvenirLTStd-Book.otf");
}
@font-face {
  font-family: Avenir;
  font-weight: 500;
  src: url("./assets/fonts/AvenirLTStd-Medium.otf");
}
@font-face {
  font-family: Avenir;
  font-weight: 800;
  src: url("./assets/fonts/AvenirLTStd-Roman.otf");
}
@font-face {
  font-family: Raleway;
  font-weight: 400;
  src: url("./assets/fonts/Raleway-Regular.ttf");
}
@font-face {
  font-family: Raleway;
  font-weight: 800;
  src: url("./assets/fonts/Raleway-Bold.ttf");
}
:root {
  --amarillo: #d4af37;
  --gris: #e5e5e5;
  --verde: #0dd675;
}
// html,
// body {
//   overflow-x: hidden;
//   /* Safari compatibility */
//   height: 100%;
//   width: 100%;
//   transform: translate3d(0, 0, 0);
// }
body {
  background-color: black;
  font-family: Avenir;
  font-weight: 400;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.contenedor {
  max-width: 1440px;
  width: 100%;
  margin: auto;
  position: relative;
}
.mallaGeneral {
  display: grid;
  grid-template-columns: 1fr 4fr;
  background-color: white;
  width: 100%;
  min-height: 100vh;
}
.principal {
  padding: 50px;
  width: 100%;
  background-color: white;
  // overflow-x: hidden;
}
h1 {
  @include ralewayNormal(18px);
}
.loading {
  width: 20%;
  margin: 80px 40%;
}
.boton {
  background-color: transparent;
  border: none;
  border-radius: 10px;
}
.botonAmarillo {
  color: white;
  background-color: var(--amarillo);
}
p {
  margin: 0;
}
select {
  -moz-appearance: none;
  -webkit-appearance: none;
  background-image: url("./assets/images/Iconos/flechaSelector.svg");
  background-repeat: no-repeat;
  background-position: right;
  background-size: 10px;
  padding: 5px 15px;
}

.modal-body {
  position: initial;
}

// ESTILOS GENERALES GRIDDLE
.griddle-filter {
  @include estiloInput;
  padding: 5px;
  background-image: url("./assets/images/Iconos/filtro.png");
  background-position: right;
  background-repeat: no-repeat;
  background-size: 20px;
}
.griddle-filter::placeholder {
  opacity: 0;
}
.griddle-page-select {
  @include estiloInput;
}
.columnaCentrada {
  @include avenirNormal(13px);
  font-weight: 500;
}
.griddle-table-heading-cell {
  @include avenirNormal(13px);
  font-weight: 500;
}
.tablaGriddle {
  text-align: center;
}
.slick-slider {
  width: 70%;
  max-width: 1200px;
  margin: auto; // The widest it will go on a responsive website
}
.swiper-button-next,
.swiper-button-prev {
  color: var(--amarillo) !important;
}
.swiper-pagination-bullet-active {
  background-color: var(--amarillo) !important;
}
.swiper-button-prev:after,
.swiper-button-next:after {
  font-size: 20px;
}
@media screen and(max-width:900px) {
  .mallaGeneral {
    grid-template-columns: 1fr;
    height: auto;
    min-height: 100vh;
  }
  .loading {
    width: 60%;
    margin: 50px 20%;
  }
}
@media screen and (max-width: 550px) {
  .principal {
    padding: 50px 10px;
  }
}
