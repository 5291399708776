@import "../../../mixins.scss";

.subidaArchivos {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  margin-top: 50px;
  .archivo {
    @include sombraRedonda();
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    padding: 10px 15px;
    border-radius: 20px;
    grid-gap: 10px;
    min-height: 84px;
    .tipoArchivo {
      @include alinearFlex();
      flex-direction: column;
      align-items: flex-start;
      span {
        @include ralewayNormal(11px);
        font-weight: bold;
      }
      p {
        @include ralewayNormal(11px);
      }
    }

    .nombreArchivo {
      @include alinearFlex();
      input {
        @include estiloInput();
        pointer-events: none;
        padding: 5px;
        height: 30px;
      }
    }
  }
  .subirArchivo {
    @include alinearFlex();
    text-align: center;
    input {
      display: none;
    }
    label {
      @include avenirNormal(11px);
      background-color: #b6b6b6;
      padding: 7px 10px;
      border-radius: 20px;
      color: white;

      min-height: 30px;
    }
  }
}
.enviarArchivos {
  text-align: center;
  margin-top: 50px;
  p {
    @include avenirNormal(13px);
    font-weight: 500;
  }
  input[type="button"] {
    @include avenirNormal(12px);

    padding: 10px 20px;
    background-color: var(--amarillo);
    color: white;
  }
}
@media screen and (max-width: 1100px) {
  .subidaArchivos {
    grid-template-columns: 1fr;
  }
}
