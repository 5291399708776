@import "../../../mixins.scss";
.contenedorContrasena {
  width: 70%;
  margin: auto;

  form {
    margin-top: 50px;
    text-align: center;
    .contrasena {
      @include sombraRedonda();
      padding: 20px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin-bottom: 20px;
      border-radius: 15px;
      label {
        margin: 0 40px 0 20px;
        @include alinearFlex();
      }

      .contrasenaVer {
        position: relative;
        img {
          width: 25px;
          position: absolute;
          top: 12px;
          right: 10px;
          z-index: 5;
        }
        input {
          @include estiloInput();
          width: 100%;
          padding: 8px 10px;
        }
      }
    }
    input[type="submit"] {
      background-color: var(--amarillo);
      padding: 10px 20px;
      @include avenirNormal(12px);
      font-weight: 500;
      color: white;
      margin-top: 50px;
    }
  }
}
@media screen and (max-width: 900px) {
  .contenedorContrasena {
    width: 90%;
  }
}
@media screen and (max-width: 500px) {
  .contenedorContrasena {
    form {
      .contrasena {
        grid-template-columns: 1fr;
      }
    }
  }
}
