@import "../../../mixins.scss";
.contenedorDetalles {
  margin-top: 40px;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 30px;
  form {
    @include sombraRedonda();
    border-radius: 33px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    padding: 40px;
    .detalleUnico {
      label {
        width: 30%;
      }
      input {
        float: right;
        width: 70%;
        padding: 5px;
        border-radius: 10px;
        border: 1px solid var(--gris);
      }
      .botonGuardar {
        width: 30%;
        margin: 10px 35%;
        padding: 5px 0;
      }
    }
    .detalleUnico:first-of-type,
    .detalleUnico:nth-of-type(2),
    .detalleUnico:nth-of-type(3),
    .detalleUnico:nth-of-type(8),
    .detalleUnico:nth-of-type(9),
    .detalleUnico:nth-of-type(10),
    .detalleUnico:nth-of-type(11) {
      grid-column: span 2;
    }
    .detalleUnico:nth-of-type(10) {
      display: flex;
      align-items: center;
    }
  }
  .imagenPerfil {
    text-align: center;
    .contenedorImagenPerfil {
      @include sombraRedonda();
      border-radius: 33px;
      position: relative;

      img {
        width: 50%;
        margin: 20px 0;
        border-radius: 200px;
      }
      span {
        @include ralewayNormal(14px);
        font-weight: bold;
        display: block;
        color: var(--amarillo);
        padding: 20px 0;
      }
      button {
        position: absolute;
        top: 0;
        right: 0;

        img {
          border-radius: 1px;
        }
      }
    }
  }
}
.contenedorBotonesModal {
  text-align: right;
  button {
    @include avenirNormal(13px);
    padding: 5px 10px;
    margin: 5px;
  }
}
@media screen and (max-width: 900px) {
  .contenedorDetalles {
    grid-template-columns: 1fr;

    .imagenPerfil {
      grid-row: 1/2;
      .contenedorImagenPerfil {
        width: 40%;
        margin: auto;
      }
    }
  }
}
@media screen and (max-width: 550px) {
  .contenedorDetalles {
    form {
      padding: 30px 10px;
      grid-template-columns: 1fr;

      .detalleUnico:first-of-type,
      .detalleUnico:nth-of-type(2),
      .detalleUnico:nth-of-type(3),
      .detalleUnico:nth-of-type(8),
      .detalleUnico:nth-of-type(9),
      .detalleUnico:nth-of-type(10),
      .detalleUnico:nth-of-type(11) {
        grid-column: span 1;
      }
    }
  }
}
