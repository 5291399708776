@import "../../../mixins.scss";

.transacciones {
  overflow-x: hidden;
  min-height: 1200px;
  h1 {
    margin-top: 50px;
  }
}

.columnaCentrada {
  text-align: center;
}
.tablaGriddle {
  @include sombraRedonda();
  margin: 10px 0;
  min-width: 500px;
}

.tabla {
  // width: 100%;
  // overflow-x: auto;
  div {
    width: 100%;
    overflow-x: auto;
  }
}
.contenedorImagenSinDatos {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: white;
  z-index: 20;
  text-align: center;
  img {
    width: 150px;
  }
}
.ticketsAbiertos {
  position: relative;
  margin: 50px 0;
  width: 100%;
  overflow: auto;
}
