@import "../../../mixins.scss";
.depositarGeneral {
  width: 100%;
  overflow-x: hidden;
}

.contenedorDepositos {
  margin-top: 50px;
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 30px;
  width: 100%;

  .cuentaDepositos {
    p {
      margin: 20px;
      @include ralewayNormal(16px);
    }
    .selectorDepositos {
      margin-top: 30px;
      @include alinearFlex();
      @include sombraRedonda();
      @include avenirNormal(16px);
      font-weight: 500;
      color: #7c7c7c;
      padding: 30px 0;
      span {
        margin-left: 20px;
      }
      select {
        @include avenirNormal(16px);
        font-weight: 500;
        @include estiloInput();
        border: none;
        padding: 15px;
        margin-left: 5px;
        margin-right: 5px;
      }
    }
  }
  .tarjetasDepositos {
    max-width: 100%;
    overflow: hidden;
    .tituloDepositos {
      display: flex;
      justify-content: space-between;
      align-items: center;
      button {
        @include avenirNormal(12px);
        padding: 10px 20px;
        margin-right: 20px;
      }
    }
    .contenedorSlider {
      width: 100%;
      margin: 10px auto;
      max-width: 500px;
      @include sombraRedonda();
    }
  }
  .efectivoDepositos {
    max-width: 100%;
    grid-column: span 2;
    overflow: hidden;
    &__legend {
      @include ralewayNormal(16px);
      width: 100%;
      text-align: center;
    }
  }
}
.Pagotarjeta {
  grid-area: 2/1/3/3;
  button {
    @include avenirNormal(12px);
    padding: 10px 20px;
    display: block;
    margin: 0 auto;
    background-color: var(--verde);
    color: #FFFFFF;
    font-size: 1rem;
  }
}
.formularioTarjeta {
  label {
    width: 100%;
    margin-top: 20px;
  }
  label[for="paymentAmount"] {
    position: relative;
    margin-bottom: 0;
    &::after {
      @include alinearFlex();
      @include ralewayNormal(12px);
      position: absolute;
      content: attr(data-currency);
      width: 40px;
      height: 40px;
      background-color: var(--gris);
      color: #1c1818;
      text-transform: uppercase;
      border: 1px solid #c5c5c5;
      border-radius: 0 10px 10px 0;
      right: 0;
      bottom: 0;
    }
    input {
      margin-top: 8px;
    }
  }
  input, select {
    @include estiloInput();
    width: 100%;
    padding: 7px;
  }

  button {
    float: right;
    margin-top: 20px;
    margin-left: 20px;
    padding: 10px 20px;
  }
}

.loader-modal {
  @include alinearFlex();
  position: absolute;
  background-color: rgba(33, 33, 33, .4);
  inset: 0;
  z-index: 10;
  img {
    width: 100px;
    height: 100px;
    object-fit: contain;
  }
}

@media screen and (max-width: 1000px) {
  .Pagotarjeta {
    grid-area: initial;
  }
  .contenedorDepositos {
    grid-template-columns: 1fr;
    .efectivoDepositos {
      grid-column: span 1;
    }
  }
}
@media screen and (max-width: 550px) {
}
