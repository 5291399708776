@import "../../mixins";

.titular {
  @include imagenFondo;
  @include alinearFlex;
  justify-content: space-between;
  padding: 25px 30px;
  background-image: url("../../assets/images/header.jpg");

  img {
    width: 120px;
  }

  .contenedorUsuario {
    @include alinearFlex;
    justify-content: space-between;
    flex-direction: row;

    .ajustesLenguaje {
      button {
        border: none;
        background-color: transparent;
      }
      position: relative;
      display: inline-block;
      .iconoLenguaje {
        filter: invert(88%) sepia(97%) saturate(3528%) hue-rotate(323deg)
          brightness(86%) contrast(93%);
        width: 25px;
        height: 25px;
      }

      .selectorLenguaje {
        display: none;
        position: absolute;
        z-index: 5;
        background-color: white;
        padding: 20px 0 10px 0;
        top: 30px;
        left: -30px;
        clip-path: polygon(
          60% 10%,
          100% 10%,
          100% 100%,
          0 100%,
          0 10%,
          40% 10%,
          50% 0
        );

        button {
          padding: 10px 20px;
          width: 100%;

          &:hover,
          &.botonActivo {
            background-color: var(--amarillo);
          }
        }
      }
    }
    .datos {
      color: white;
      display: grid;
      grid-template-columns: 1fr 1fr;

      margin: 0 20px;
      .nivel {
        grid-column: span 2;
        text-align: right;
        span {
          @include avenirNormal(14px);
          color: var(--amarillo);
        }
      }
      .nombre {
        grid-column: span 2;
        text-align: right;
        span {
          @include ralewayNormal(24px);
        }
      }
      .perfil,
      .perfil a,
      .cerrar {
        background: none;
        color: white;
        border: none;
        span {
          @include avenirNormal(14px);
        }
      }
      .perfil {
        text-align: center;

        border-right: 1px solid var(--amarillo);
      }
      .cerrar {
        margin-left: 15px;
      }
    }
    .imagen {
      border-radius: 50px;
      img {
        max-width: 50px;
        margin: auto;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .titular {
    padding: 25px 10px;
    .contenedorUsuario {
      margin-right: 45px;
      .datos {
        display: none;
      }
      .imagen {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 550px) {
}
